import type { OperationVariables } from '@apollo/client'

import type {
  PlaySlipStatus,
  SingleOrdersQuery,
} from '~/@types/generated/backend/graphql-schema-types'

type Parameters = {
  limit: number
  status: PlaySlipStatus[]
}

const updateQuery = (
  previousResult: SingleOrdersQuery,
  {
    fetchMoreResult,
    variables,
  }: {
    fetchMoreResult?: SingleOrdersQuery
    variables?: OperationVariables
  },
): SingleOrdersQuery => {
  if (!fetchMoreResult) return previousResult
  const { count, singleOrders } = fetchMoreResult.singleOrders

  if (
    variables?.limit === previousResult.singleOrders.singleOrders.length &&
    variables.offset === 0
  ) {
    return fetchMoreResult
  }

  return {
    singleOrders: {
      ...previousResult.singleOrders,
      count,
      singleOrders: [
        ...previousResult.singleOrders.singleOrders,
        ...singleOrders,
      ],
    },
  }
}

export const useLazySingleOrders = ({ limit, status }: Parameters) =>
  useFetchMoreWrapper<ReturnType<typeof useSingleOrdersLazyQuery>>(
    () =>
      useSingleOrdersLazyQuery(
        {
          filter: { status },
          limit,
          offset: 0,
        },
        { fetchPolicy: 'cache-and-network' },
      ),
    updateQuery,
    (result) => result?.singleOrders.singleOrders.length,
    (result) => result?.singleOrders.count,
    'singleorders.error',
  )
