<template>
  <MyOrdersTab v-bind="{ fetchActive, fetchPast, visible }" type="singleorder">
    <template #no-results>
      <MyOrdersNoResults variant="single-order" />
    </template>

    <template #active="{ result }">
      <li
        v-for="singleOrder in getSingleOrders(result) as ActivePlaySlip[]"
        :key="singleOrder.orderId"
      >
        <MyOrdersSingleOrderCardActive :data="singleOrder" />
      </li>
    </template>

    <template #past="{ result }">
      <li
        v-for="singleOrder in getSingleOrders(result) as ClosedPlaySlip[]"
        :key="singleOrder.orderId"
      >
        <MyOrdersSingleOrderCardPast :data="singleOrder" />
      </li>
    </template>
  </MyOrdersTab>
</template>

<script lang="ts" setup>
import {
  type ActivePlaySlip,
  type ClosedPlaySlip,
  PlaySlipStatus,
  type SingleOrdersQuery,
} from '~/@types/generated/backend/graphql-schema-types'
import type { MyOrdersTabImplementationProps } from '~/features/MyOrders/types'

const ACTIVE_STATUS = [
  PlaySlipStatus.ManualReview,
  PlaySlipStatus.Ordered,
  PlaySlipStatus.Preliminary,
  PlaySlipStatus.Purchased,
]

const PAST_STATUS = [
  PlaySlipStatus.Closed,
  PlaySlipStatus.Cancelled,
  PlaySlipStatus.Rejected,
]

defineProps<MyOrdersTabImplementationProps>()

const fetchActive = () =>
  useLazySingleOrders({
    limit: 20,
    status: ACTIVE_STATUS,
  })

const fetchPast = () =>
  useLazySingleOrders({
    limit: 3,
    status: PAST_STATUS,
  })

const getSingleOrders = (result: SingleOrdersQuery) =>
  result.singleOrders.singleOrders
</script>
